<template>
  <div class="headersPContent">
    <div class="headersPLeft flex center1" @click="jump(0)">
      <img src="@/assets/img/logo.png" alt="" />
    </div>
    <div class="headersPRight flex center1">
      <span  class="flex" v-for="(item,index) in productList" :key="index"  @click="jumpUrl(item)" style="margin:0 20px ;cursor:pointer;font-size: 18px;">
        <!-- <img :src="item.imgUrl" alt="" /> -->
        {{item.title}}
      </span>
      <!-- <el-dropdown placement="bottom-end" >
        <span class="el-dropdown-link">
          <img src="@/assets/img/header-right.png" alt="" />
        </span>
        <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item

            v-for="item in dropdownMenu"
            :key="item.jumpIndex"
            @click.native="jump(item)"
            >{{ item.name }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdownMenu: [
        { name: "首页", jumpIndex: 0 },
        { name: "多重方案 更多选择", jumpIndex: 1 },
        { name: "资料库", jumpIndex: 2 },
        { name: "资讯", jumpIndex: 3 },
        { name: "建匠科技", jumpIndex: 4 },
      ],
      productList: [
        {
          imgUrl: require("@/assets/img/gr.png"),
          title: "实名宝",
          user: "劳资员",
          scene: "用工管理",
          jumpUrl: "http://realname.hnbimzj.com/#/Login",
        },
        {
          imgUrl: require("@/assets/img/lwgl.png"),
          title: "易匠通",
          user: "雇主、经纪人等",
          scene: "众包任务、决策分析",
          jumpUrl: "",
        },
        // {
        //   imgUrl: require("@/assets/img/zf.png"),
        //   title: "建匠支付",
        //   user: "财务人员",
        //   scene: "报酬支付、税务代征",
        //   jumpUrl: "http://pay.hn-yjt.com/#/",
        // },
        {
          imgUrl: require("@/assets/img/qyfw.png"),
          title: "企业服务",
          user: "公司劳务管理部门",
          scene: "风险预警、资源库",
          jumpUrl: "http://47.105.129.158/#/loginCompany",
        },
      ],
    };
  },
  methods: {
    jump(e) {
      console.log(e)
      this.$emit('headerJump', e.jumpIndex);
    },
    jumpUrl(e) {
      if (e.jumpUrl != "") {
        window.open(e.jumpUrl)
        // window.location.href = e.jumpUrl
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>