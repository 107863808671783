<template>
  <div class="secondIndexContent">
    <div class="secondIndexContentTitle">
      <img src="@/assets/img/fanganLogo.png" alt="" />
    </div>
    <div class="secondIndexContentC">
      <swiper :options="swiperOption" ref="mySwiperSecond">
      <swiper-slide>
          <div class="secondIndexContentList flex">
            <div class="secondIndexContentListL">
              <img src="@/assets/img/fangan03.png" alt="" />
            </div>
            <div class="secondIndexContentListR">
              <img src="@/assets/img/fanganweinzi01.png" alt="" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="secondIndexContentList flex">
            <div class="secondIndexContentListL">
              <img src="@/assets/img/fangan01.png" alt="" />
            </div>
            <div class="secondIndexContentListR">
              <img src="@/assets/img/fanganweinzi02.png" alt="" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="secondIndexContentList flex">
            <div class="secondIndexContentListL">
              <img src="@/assets/img/fangan02.png" alt="" />
            </div>
            <div class="secondIndexContentListR">
              <img src="@/assets/img/fanganweinzi03.png" alt="" />
            </div></div
        ></swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <footerP />
    <div class="backHomeT flex center1 flexCC" @click="nextBtn" >
      <img src="@/assets/img/xxzh.png" alt=""  style="width:1.5rem;position:relative;top:15px;"/>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import footerP from "@/components/footerP.vue";
export default {
  components: {
    swiper,
    swiperSlide,
    footerP
  },
  data() {
    return {
      swiperOption: {
        loop: false,
        autoplay: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: false, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
   
    swiper() {
      return this.$refs.mySwiperSecond.swiper;
    },
  },
  methods: {
     nextBtn(){
      this.$emit('headerJump',2)
    },
  },
};
</script>

<style lang="scss" scoped>
</style>