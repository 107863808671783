import axios from 'axios';
var service = axios.create({
        baseURL: 'http://116.63.111.85:9902/' || process.env.BASE_API,
        // baseURL:'http://192.168.50.88:90'|| process.env.BASE_API,
        timeout: 20000
    })
    //添加请求拦截器
service.interceptors.request.use(function(config) {
    console.log('config', config)
    config.headers['Content-type'] = 'application/json; charset=utf-8';
    return config;
}, function(error) {
    return Promise.reject(error)
})

//响应拦截
service.interceptors.response.use(
    response => {
        console.log("response", response);
        if (response) {

        }
        return response;
    },
    err => {
        console.log('err', err)
    }
)

export default {
    //get请求
    get(url, param) {
        return new Promise((resolve, reject) => {
            service({
                method: 'get',
                url,
                params: param,
            }).then(res => { //axios返回的是一个promise对象
                resolve(res) //resolve在promise执行器内部 
            }).catch(err => {
                reject(err)
            })
        })
    },
    //post请求
    post(url, param) {
        return new Promise((resolve, reject) => {
            service({
                method: 'post',
                url,
                data: param,
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}