<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    // //禁止F12键盘事件
    // document.addEventListener("keydown", function (event) {
    //   return 123 != event.keyCode || (event.returnValue = false);
    // });
  },
};
</script>
<style lang="less">
</style>
