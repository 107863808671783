<template>
  <div>
    <headersP @headerJump="headerJump" />
    <div class="fourthIndexContent">
      <div class="newsDetailContent">
        <div class="calculateTitle">用工方案测算</div>
        <div class="calculateTable">
          <div class="calculateTableList1">
            <div class="calculateTableListL1">人工费</div>
            <div class="calculateTableListR1">{{ money }}万元</div>
          </div>
          <div class="calculateTableList2 flex center1">
            <div class="calculateTableListL2">模式</div>
            <div class="calculateTableListM2">传统劳务</div>
            <div class="calculateTableListR2">
              <div class="calculateTableListR2T">劳务SaaS服务</div>
              <div class="calculateTableListR2B flex">
                <div class="flex blinput">
                  自营模块
                  <el-input
                    type="number"
                    min="0"
                    max="100"
                    v-model="jjzybl"
                  ></el-input
                  >%
                </div>
                <div class="flex blinput">
                  专业用工<el-input
                    type="number"
                    min="0"
                    max="100"
                    v-model="zyygbl"
                  ></el-input
                  >%
                </div>
              </div>
            </div>
          </div>
          <div class="calculateTableList3 flex center1">
            <div class="calculateTableListL3">增值税率</div>
            <div class="calculateTableListM3">3%</div>
            <div class="calculateTableListR3">
              <div>3%</div>
              <div>6%</div>
            </div>
          </div>
          <div class="calculateTableList3 flex center1">
            <div class="calculateTableListL3">服务税率</div>
            <div class="calculateTableListM3">{{ ctlwSl }}%</div>
            <div class="calculateTableListR3">
              <div>{{ jjzySl }}%</div>
              <div>{{ zyfwSl }}%</div>
            </div>
          </div>
          <div class="calculateTableList3 flex center1">
            <div class="calculateTableListL3">合同额</div>
            <div class="calculateTableListM3">{{ ctlwHte }}</div>
            <div class="calculateTableListR3">
              <div>{{ jjzyHte }}</div>
              <div>{{ zyfwHte }}</div>
            </div>
          </div>

          <div class="calculateTableList3 flex center1">
            <div class="calculateTableListL3">增值税</div>
            <div class="calculateTableListM3">{{ ctlwZzse }}</div>
            <div class="calculateTableListR3">
              <div>{{ jjzyZzse }}</div>
              <div>{{ zyfwZzse }}</div>
            </div>
          </div>
          <div class="calculateTableList3 flex center1">
            <div class="calculateTableListL3">附加税</div>
            <div class="calculateTableListM3">{{ ctlwFjse }}</div>
            <div class="calculateTableListR3">
              <div>{{ jjzyFjse }}</div>
              <div>{{ zyfwFjse }}</div>
            </div>
          </div>
          <div class="calculateTableList2 flex center1">
            <div class="calculateTableListL2">项目成本</div>
            <div class="calculateTableListM2">{{ ctlwCost }}</div>
            <div class="calculateTableListR2">
              <div
                class="calculateTableListR2B flex"
                style="margin-top: 0; margin-bottom: 8px"
              >
                <div>{{ jjzyCost }}</div>
                <div>{{ zyfwCost }}</div>
              </div>
              <div class="calculateTableListR2T">{{ jjzyZ }}</div>
            </div>
          </div>
          <div class="calculateTableList1 flexCC" style="font-size:1.2rem">
            使用劳务SaaS服务模式可降低用工成本：<span
              style="color: #eb5c01; position: relative; top: 2px"
            >
              {{ cbjdl }}</span
            >万元；提升项目效益：<span style="color: #eb5c01; position: relative; top: 2px"> {{ xytsl }}%</span
            >
          </div>
        </div>
      </div>
      <div class="fwimg">
        <img src="@/assets/img/fw.png" alt="" />
      </div>
    </div>
    <!-- <footerP align="center" /> -->
  </div>
</template>

<script>
import headersP from "@/components/headersP.vue";
import footerP from "@/components/footerP.vue";
export default {
  components: {
    headersP,
    footerP,
  },
  created() {
    if (this.$route.query) {
      this.money = this.$route.query.money;
    }
  },
  mounted() {
    this.hteCount();
    this.zzsCount();
    this.fjsCount();
    this.costCount();
  },
  data() {
    return {
      money: "",
      ctlwHte: "",
      ctlwSl: "5.5",
      jjzySl: "3.9",
      zyfwSl: "7",
      jjzybl: "70",
      zyygbl: "30",
      jjzyHte: "",
      zyfwHte: "",
      ctlwZzse: "",
      jjzyZzse: "",
      zyfwZzse: "",
      ctlwFjse: "",
      jjzyFjse: "",
      zyfwFjse: "",
      ctlwCost: "",
      jjzyCost: "",
      zyfwCost: "",
      jjzyZ: "",
      cbjdl: "",
      xytsl: "",
    };
  },
  watch: {
    //   jjzybl:"70",
    //   zyygbl:"30",
    jjzybl(newV, oldV) {
      if (newV && newV != "") {
        let max = 100;
        if (Number(newV) > max) {
          this.jjzybl = max;
          this.zyygbl = 0;
        } else {
          this.zyygbl = 100 - Number(newV);
        }
        this.hteCount();
        this.zzsCount();
        this.fjsCount();
        this.costCount();
      }
    },
    zyygbl(newV, oldV) {
      if (newV && newV != "") {
        let max = 100;
        if (Number(newV) > max) {
          this.zyygbl = max;
          this.jjzybl = 0;
        } else {
          this.jjzybl = 100 - Number(newV);
        }
        this.hteCount();
        this.zzsCount();
        this.fjsCount();
        this.costCount();
      }
    },
  },
  methods: {
    headerJump(e) {
      console.log("headerJump", e);
      this.$router.push({ path: "/Home?jump=" + e });
      console.log(this.$router);
      //   this.$refs.mySwiper.swiper.slideTo(e, 500, false);
    },
    hteCount() {
      this.ctlwHte = (
        Number(this.money) + Number(this.money * (this.ctlwSl / 100))
      ).toFixed(2);
      this.jjzyHte = (
        Number(this.money * (Number(this.jjzybl) / 100)) +
        Number(this.money * (Number(this.jjzybl) / 100) * (this.jjzySl / 100))
      ).toFixed(2);
      console.log(Number(this.jjzybl) / 100);
      this.zyfwHte = (
        Number(this.money * (Number(this.zyygbl) / 100)) +
        Number(this.money * (Number(this.zyygbl) / 100) * (this.zyfwSl / 100))
      ).toFixed(2);
    },
    zzsCount() {
      this.ctlwZzse = ((this.ctlwHte * 0.03) / 1.03).toFixed(2);
      this.jjzyZzse = ((this.jjzyHte * 0.03) / 1.03).toFixed(2);
      this.zyfwZzse = ((this.zyfwHte * 0.06) / 1.06).toFixed(2);
    },
    fjsCount() {
      this.ctlwFjse = (this.ctlwZzse * 0.12).toFixed(2);
      this.jjzyFjse = (this.jjzyZzse * 0.12).toFixed(2);
      this.zyfwFjse = (this.zyfwZzse * 0.12).toFixed(2);
    },
    costCount() {
      this.ctlwCost = (this.ctlwHte - this.ctlwZzse - this.ctlwFjse).toFixed(2);
      this.jjzyCost = (this.jjzyHte - this.jjzyZzse - this.jjzyFjse).toFixed(2);
      this.zyfwCost = (this.zyfwHte - this.zyfwZzse - this.zyfwFjse).toFixed(2);
      this.jjzyZ = (Number(this.jjzyCost) + Number(this.zyfwCost)).toFixed(2);
      this.cbjdl = (Number(this.ctlwCost) - Number(this.jjzyZ)).toFixed(2);
      this.xytsl = ((this.cbjdl / Number(this.ctlwCost)) * 100).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>