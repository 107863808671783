module.exports={
    changeState(state,parameters){
        if(parameters&&typeof parameters ==="object"){
            Object.keys(parameters).forEach((v,k)=>{
                state[v]=parameters[v]
            })
        }
        else{
                     throw "参数不能为空";     
        }    
      }
}