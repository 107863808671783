<template>
  <div class="recommendPage">
    <headersP @headerJump="headerJump" />
    <swiper :options="swiperOption" ref="mySwiper" class="swiper-no-swiping">
      <swiper-slide>
        <firstIndex @headerJump="headerJump" />
      </swiper-slide>
      <swiper-slide>
        <secondIndex @headerJump="headerJump" />
      </swiper-slide>
      <swiper-slide>
        <thirdIndex @headerJump="headerJump" />
      </swiper-slide>
      <swiper-slide>
        <fourthIndex @headerJump="headerJump" />
      </swiper-slide>
      <swiper-slide>
        <fifthIndex @backIndex="backIndex" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import firstIndex from "./firstIndex.vue";
import secondIndex from "./secondIndex.vue";
import thirdIndex from "./thirdIndex.vue";
import fourthIndex from "./fourthIndex.vue";
import fifthIndex from "./fifthIndex.vue";
import headersP from "@/components/headersP.vue";
export default {
  components: {
    swiper,
    swiperSlide,
    firstIndex,
    headersP,
    secondIndex,
    thirdIndex,
    fourthIndex,
    fifthIndex,
  },
  data() {
    return {
      swiperOption: {
        loop: false,
        autoplay: false,
        autoHeight: true,
        direction: "vertical",
        //滚轮切换
        mousewheel: true,
      },
    };
  },
  methods: {
    backIndex() {
      this.$refs.mySwiper.swiper.slideTo(0, 500, false);
    },
    headerJump(e) {
      console.log("headerJump", e);
      this.$refs.mySwiper.swiper.slideTo(e, 500, false);
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    if (this.$route.query.jump) {
      this.$refs.mySwiper.swiper.slideTo(this.$route.query.jump, 500, false);
    }
  },
};
</script>
<style scoped >
</style>

