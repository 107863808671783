import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false


import echarts from 'echarts';
Vue.prototype.$echarts = echarts;

import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css';

import './util/rem'

import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI)

import Videojs from 'video.js'
 
import 'video.js/dist/video-js.css'
 
Vue.prototype.$video = Videojs

import '@/assets/css/base.css';

import '@/assets/css/style.css';

import $api  from  './common/api';

Vue.prototype.$api=$api;

import VueTouch from 'vue-touch'

Vue.use(VueTouch, {name: 'v-touch'})

import moment from 'moment'

Vue.prototype.$moment = moment;
import Toast from './components/toast'

Vue.use(Toast)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
