<template>
  <transition name="fade" v-if="message">
    <div class="toast" v-if="visible">{{message}}</div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      message: ''
    }
  }
}
</script>

<style scoped>
.toast {
  padding: 12px 20px;
  min-width:200px;
  max-width: 500px;
  color: #fff;
  background:rgba(0,0,0,.6);
  text-align: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  font-size: 14px;
  z-index:3000
}
/* vue动画过渡效果设置 */
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
