<template>
  <div class="productDetail">
    <div class="productBack" @click="$router.push({ path: '/Home' })">
      <img src="@/assets/img/back.png" alt="" />
    </div>
    <div style="width: 100%">
      <div class="productDetailT">
        <div class="productDetailTitle">
          在易匠通劳务SaaS云，<span>1 > 4</span>
        </div>
        <div class="productDetailPs">
          一个产品体系，解决四大场景工作问题，实现在线一体化劳务用工协作
        </div>
      </div>
      <div class="productDetailB flex">
        <div
          class="productDetailBList"
          v-for="(item, index) in productList"
          @click="jumpUrl(item)"
          :key="index"
        >
          <div class="productDetailBListT">
            <img :src="item.imgUrl" alt="" />
          </div>
          <div class="productDetailBListM">
            {{ item.title }}
          </div>
          <div class="productDetailBListB">
            <div>用户：{{ item.user }}</div>
            <div>场景：{{ item.scene }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productList: [
        {
          imgUrl: require("@/assets/img/gr.png"),
          title: "实名宝",
          user: "劳资员",
          scene: "用工管理",
          jumpUrl: "http://47.105.129.158/#/loginProject",
        },
        {
          imgUrl: require("@/assets/img/lwgl.png"),
          title: "易匠通",
          user: "雇主、经纪人等",
          scene: "众包任务、决策分析",
          jumpUrl: "",
        },
        {
          imgUrl: require("@/assets/img/zf.png"),
          title: "薪税结算",
          user: "财务人员",
          scene: "报酬支付、税务代征",
          jumpUrl: "http://pay.hn-yjt.com/#/",
        },
        {
          imgUrl: require("@/assets/img/qyfw.png"),
          title: "企业服务",
          user: "公司劳务管理部门",
          scene: "风险预警、资源库",
          jumpUrl: "http://47.105.129.158/#/loginCompany",
        },
      ],
    };
  },
  methods: {
    jumpUrl(e) {
      if (e.jumpUrl != "") {
        window.location.href = e.jumpUrl
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>