import server from './http';

export default {
    get_article: (param) => server.get('/api/index/get_article', param), //获取资讯

    get_detail: (param) => server.get('/api/index/get_detail', param), //获取资讯详情

    apply_record: (param) => server.get('/api/index/apply_record', param), //保存联系方式

    get_video: (param) => server.get('/api/index/get_video', param), //保存联系方式

    EWMdownload: (param) => server.get('/api/index/download', param), //二维码获取
}