<template>
  <div class="fifthIndexContent">
    <div class="secondIndexContentTitle">
      <img src="@/assets/img/jianjiang.png" alt="" />
    </div>
    <div class="thirdIndexContentC flex center1 flexJ">
        <div class="fifthIndexContentL">
            <img src="@/assets/img/jianjiangkeji1.png" alt="">
        </div>
        <div class="fifthIndexContentR">
            <img src="@/assets/img/jianjiang01.png" alt="">
        </div>
    </div>
    <footerP />
    <div class="backHomeT flex center1 flexCC" @click="backTop()" width="200px">
      <img src="@/assets/img/topJT.png" alt="" />
      回到首页
    </div>
  </div>
</template>

<script>
import footerP from "@/components/footerP.vue";
export default {
  components: {
    footerP,
  },
  methods: {
      backTop() {
        this.$emit('backIndex')
      }
  },
};
</script>

<style lang="scss" scoped>
</style>