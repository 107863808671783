<template>
  <div class="cardList">
    <div class="firstIndex">
      <div class="firstIndexL">
        <img src="@/assets/img/firstLIndex.png" alt="" />
        <div class="firstIndexLF">
          <div class="firstIndexLFList">
            <img src="@/assets/img/ewm-gzh.png" alt="" />
            <div class="firstIndexLFListText">数字建造公众号</div>
          </div>
          <div class="firstIndexLFList">
            <img src="@/assets/img/ewm-xcx.png" alt="" />
            <div class="firstIndexLFListText">易匠通小程序</div>
          </div>
        </div>
      </div>
      <div class="firstIndexR">
        <div class="firstIndexRList">
          <div class="firstIndexRListTitle">您项目的人工费</div>
          <div class="firstIndexRListInput">
            <el-input
              placeholder="请输入人工费"
              v-model.number="params.salary"
              type="number"
              :min="0"
              :precision="2"
              :controls="false"
            ></el-input>
            <div class="dw">万元</div>
          </div>
        </div>
        <div class="firstIndexRList">
          <div class="firstIndexRListTitle">您的联系电话</div>
          <div class="firstIndexRListInput">
            <el-input
              placeholder="请输入联系电话"
              v-model="params.contact"
            ></el-input>
          </div>
        </div>
        <div class="firstIndexRBtn" @click="saveContact">
          一键获得劳务用工方案
        </div>
      </div>
    </div>
    <footerP />
    <div class="backHomeT flex center1 flexCC" @click="nextBtn">
      <img
        src="@/assets/img/xxzh.png"
        alt=""
        style="width: 1.5rem; position: relative; top: 15px"
      />
    </div>
  </div>
</template>

<script>
import footerP from "@/components/footerP.vue";
export default {
  components: {
    footerP,
  },
  data() {
    return {
      params: {
        contact: "",
        salary: "",
      },
    };
  },
  created() {
    this.queryEwm();
  },
  methods: {
    queryEwm() {
      this.$api.EWMdownload().then(res=>{

      })
    },
    nextBtn() {
      this.$emit("headerJump", 1);
    },
    saveContact() {
      if (!this.params.salary) {
        this.$toast("请输入人工费");
        return;
      }
      if (!this.params.contact) {
        this.$toast("请输入联系电话");
        return;
      }
      var myreg = /^[1][3,5,7,8][0-9]{9}$/;
      if (!myreg.test(this.params.contact)) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      // this.$router.push({ path: "/calculate?money="+this.params.salary });
      this.$api.apply_record(this.params).then((res) => {
        if (res.data.code == 1) {
          this.$router.push({ path: "/calculate?money=" + this.params.salary });
        } else {
          this.$toast(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>