<template>
  <div class="thirdIndexContent">
    <div class="secondIndexContentTitle">
      <img src="@/assets/img/ziliaoku.png" alt="" />
    </div>
    <div class="thirdIndexContentC secondIndexContentC">
      <swiper :options="swiperOption" ref="mySwiperThird">
        <swiper-slide>
          <div class="thirdIndexList flex">
            <div class="thirdIndexListNum">01</div>
            <div class="thirdIndexListVideo flex">
              <div
                class="thirdIndexListVideoList"
                v-for="item in VideoList"
                :key="item.id"
              >
                <img
                  @click="playVideoS(item)"
                  class="playIcon"
                  src="@/assets/img/playIcon.png"
                  alt=""
                />
                <div class="thirdIndexListVideoTop">
                  <img :src="item.cover" alt="" />
                </div>
                <div class="thirdIndexListVideoBottom">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <div class="videoPlayDialog" v-if="dialogVisible" @click="closeDialog">
      <div class="videoPlayDialogContent">
        <div class="videoPlayDialogContentT">
          {{ DialogTitle }}
          <i class="el-icon-close closeDialog" @click="closeDialog"></i>
        </div>
        <div style="text-align: center">
          <video controls id="thirdVideo" oncontextmenu = "return false">
            <source
              :src="PlayVideoUrl"
              type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            />
          </video>
        </div>
      </div>
    </div>
    <footerP />
    <div class="backHomeT flex center1 flexCC" @click="nextBtn">
      <img src="@/assets/img/xxzh.png" alt=""  style="width:1.5rem;position:relative;top:15px;"/>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import footerP from "@/components/footerP.vue";
export default {
  components: {
    swiper,
    swiperSlide,
    footerP,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiperThird.swiper;
    },
  },
  data() {
    return {
      DialogTitle: "",
      dialogVisible: false,
      PlayVideoUrl: "",
      VideoList: [
       
      ],
      swiperOption: {
        loop: false,
        autoplay: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: false, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  created() {
    this.queryDetail()
  },
  methods: {
    queryDetail(){
      this.$api.get_video().then(res=>{
        if(res.data.code==1){
          this.VideoList = res.data.data;
        }
      })
    },
    nextBtn(){
      this.$emit('headerJump',3)
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    playVideoS(e) {
      this.DialogTitle = e.title;
      this.PlayVideoUrl = e.videoUrl;
      this.dialogVisible = true;
      setTimeout(() => {
        let dom = document.getElementById("thirdVideo");
        console.log(dom);
        dom.play();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>