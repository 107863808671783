import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import newsDetail from '../views/newsDetail.vue'
import calculate from '../views/calculate.vue'
import product from '../views/product.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home
  },
  {
    path: '/newsDetail/:id',
    name: 'newsDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: newsDetail
  },
  {
    path: '/product',
    name: 'product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: product
  },
  {
    path: '/calculate',
    name: 'calculate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: calculate
  },
  
]

const router = new VueRouter({
  mode: "hash",           // hash 加#       history   /
  base: process.env.BASE_URL,
  routes
})



// 路由守卫-前
router.beforeEach((to, from, next) => {
  next();
});
// 路由守卫-后
router.afterEach(() => {

})


export default router
