<template>
  <div>
    <headersP @headerJump="headerJump" />
    <div class="fourthIndexContent">
      <div class="newsDetailContent">
        <div class="secondIndexContentTitle">
          <img src="@/assets/img/zixun.png" alt="" />
        </div>
        <div class="newsDetailContentTitle">{{ title }}</div>
        <div class="newsDetailContentDate">{{ date }}</div>
        <div class="newsDetailContentC" v-html="content">
            {{content}}
        </div>
      </div>

    </div>
    <div class="newDetailFiex" @click="headerJump(3)">
      <img src="@/assets/img/back.png" alt="" />
    </div>
    <footerP align='center' />
  </div>
</template>

<script>
import headersP from "@/components/headersP.vue";
import footerP from "@/components/footerP.vue";
export default {
  components: {
    headersP,footerP
  },
  created() {
    this.queryDetail();
  },
  data() {
    return {
      title: "",
      date: "",
      content: "",
    };
  },
  methods: {
    headerJump(e) {
      console.log("headerJump", e);
      this.$router.push({ path: "/Home?jump="+e });
      console.log(this.$router)
    //   this.$refs.mySwiper.swiper.slideTo(e, 500, false);
    },
    queryDetail() {
      this.$api
        .get_detail({
          id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.title = res.data.data.title;
            this.date = res.data.data.date;
            this.content = res.data.data.content;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>