<template>
  <div class="fourthIndexContent">
    <div class="secondIndexContentTitle">
      <img src="@/assets/img/zixun.png" alt="" />
    </div>
    <div class="fourthIndexContentC thirdIndexContentC flex flexJ">
      <div class="fourthIndexContentCL">
        <div class="fourthIndexContentCLT">
          <img :src="newsList[0].image" alt="" />
        </div>
        <div class="fourthIndexContentCLB">
          <div class="fourthIndexContentCLBTitle">
            {{ newsList[0].title }}
          </div>
          <div class="fourthIndexContentCLBDate">{{ newsList[0].date }}</div>
          <div class="ckxq" @click="jumpDetail(newsList[0].id)">查看详情>></div>
        </div>
      </div>
      <div class="fourthIndexContentCR">
        <div
          class="fourthIndexContentCRList flex"
          v-for="(item, index) in newsList"
          :key="item.id"
        >
          <div class="fourthIndexContentCRListL" v-if="index != 0">
            <img :src="item.image" alt="" />
          </div>
          <div class="fourthIndexContentCRListR" v-if="index != 0">
            <div class="fourthIndexContentCRListT">
              {{ item.title }}
            </div>
            <div class="fourthIndexContentCRListM">
              {{ item.date }}
            </div>
            <div class="fourthIndexContentCRListB" @click="jumpDetail(item.id)">查看详情>></div>
          </div>
        </div>
      </div>
    </div>
    <footerP />
    <div class="backHomeT flex center1 flexCC" @click="nextBtn">
      <img src="@/assets/img/xxzh.png" alt=""  style="width:1.5rem;position:relative;top:15px;"/>
    </div>
  </div>
</template>

<script>
import footerP from "@/components/footerP.vue";
export default {
  components: {
    footerP,
  },
  data() {
    return {
      newsList: [
        {
          title: "“建筑用工新路径交流会”怀化站顺利举行",
          date: "2021-12-23",
          id: 1,
          image: require("@/assets/img/zixun01.png"),
        },
        {
          title: "国家税务总局发文化！严格执行关联交易申报及股权激励个税管理",
          date: "2021-12-23",
          id: 2,
          image: require("@/assets/img/zixun01.png"),
        },
        {
          title: "税务局权威答复！关于委托代征、汇总代开以及经营所得认定",
          date: "2021-12-23",
          id: 3,
          image: require("@/assets/img/zixun01.png"),
        },
        {
          title: "税务权威回应！你的年终奖将有大变化 ！到手奖金可能差上万！",
          date: "2021-12-23",
          id: 4,
          image: require("@/assets/img/zixun01.png"),
        },
      ],
    };
  },
  created() {
    this.queryDetail();
  },
  methods: {
    nextBtn(){
      this.$emit('headerJump',4)
    },
    queryDetail() {
      this.$api.get_article().then((res) => {
        if(res.data.code==1){
          this.newsList = res.data.data;
        }
      });
    },
    jumpDetail(id){
      this.$router.push({path:"/newsDetail/"+id})
    }
  },
};
</script>

<style lang="scss" scoped>
</style>