<template>
    <div class="footerPContent" :style="{'text-align':align=='center'?'center':'left','margin-top':'-20px','z-index':'999'}">
        ©2024 -2028 中湘智能建造有限公司 <a href="http://beian.miit.gov.cn" target="blank">湘ICP备2022012126号-1</a>
    </div>
</template>

<script>
    export default {
        props:['align'],
        created() {
            console.log(this.align)
        },
    }
</script>

<style lang="scss" scoped>

</style>